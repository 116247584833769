<template>
  <div>
    <a-card title="采购单">
      <div slot="extra" style="margin: -4px 0">
        <a-button type="primary" icon="left" ghost @click="$router.go(-1)">返回</a-button>
      </div>

      <a-spin :spinning="loading">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="number" label="采购单号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="order_number" label="销售单号">
                <a-input v-model="form.order_number" allowClear />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="handle_time" label="制单日期">
                <a-date-picker v-model="form.handle_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="supplier_name" label="供应商">
                <SupplierInput v-model="form.supplier_name" @select="selectSupplier" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="handler" label="制单人">
                <a-select v-model="form.handler" style="width: 100%" @change="changeHandler">
                  <a-select-option v-for="item in handlerItems" :key="item.id" :value="item.id" :item="item">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="contacts" label="联系人">
                <a-input v-model="form.contacts" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="contact_phone" label="联系人电话">
                <a-input v-model="form.contact_phone" allowClear />
              </a-form-model-item>
            </a-col>

            <!-- <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="supplier_fax" label="供应商传真">
                <a-input v-model="form.supplier_fax" allowClear />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="supplier_address" label="地址">
                <a-input v-model="form.supplier_address" allowClear />
              </a-form-model-item>
            </a-col>

            <!-- <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="order_person_phone" label="下单人电话">
                <a-input v-model="form.order_person_phone" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="order_person_fax" label="下单人传真">
                <a-input v-model="form.order_person_fax" allowClear />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="delivery_date" label="交期">
                <a-textarea v-model="form.delivery_date" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="quality" label="品质">
                <a-textarea v-model="form.quality" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="defective_handling" label="不良处理">
                <a-textarea v-model="form.defective_handling" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="payment_method" label="付款方式">
                <a-textarea v-model="form.payment_method" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="attachment_description" label="附件说明">
                <a-textarea v-model="form.attachment_description" allowClear />
              </a-form-model-item>
            </a-col> -->
            <a-col v-if="!loading" :span="6" style="width: 320px">
              <a-form-item prop="purchase_files" label="附件">
                <PurchaseFileUpload v-model="form.purchase_files" :initialItems="form.purchase_file_items" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider orientation="left">产品信息</a-divider>

        <div>
          <a-row gutter="16">
            <a-space>
              <!-- <a-input placeholder="请扫描产品条码"></a-input> -->
              <a-button type="primary" @click="openMaterialModal">添加产品</a-button>
            </a-space>
          </a-row>
          <div style="margin-top: 16px">
            <a-table rowKey="id" size="small" :columns="columns" :data-source="goodsData" :pagination="false">
              <div slot="purchase_quantity" slot-scope="value, item, index">
                <div v-if="item.isTotal">{{ value }}</div>
                <a-input-number v-else v-model="item.purchase_quantity" :min="0" size="small"></a-input-number>
              </div>
              <div slot="purchase_price" slot-scope="value, item, index">
                <a-input-number v-if="!item.isTotal" v-model="item.purchase_price" :min="0" size="small"></a-input-number>
              </div>
              <div slot="delivery_date" slot-scope="value, item, index">
                <a-date-picker
                  v-if="!item.isTotal"
                  v-model="item.delivery_date"
                  valueFormat="YYYY-MM-DD"
                  size="small"
                  style="width: 120px"
                />
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group v-if="!item.isTotal" size="small">
                  <a-button type="default" @click="editMaterial(item)">编辑</a-button>
                  <a-button type="danger" @click="removeMaterial(item)">移除</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>

        <div v-if="enableFinanceManage">
          <a-divider orientation="left">账单信息</a-divider>
          <a-row gutter="16">
            <a-col :span="4">
              <a-form-model-item prop="other_amount" label="其他费用" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-input-number v-model="form.other_amount" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="总计金额(元)" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-input-number :value="totalAmount" :disabled="true" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row gutter="16">
            <a-col :span="4">
              <a-form-model-item label="结算账户" required :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-select v-model="purchase_account_item.account" style="width: 100%">
                  <a-select-option v-for="Account in accountsItems" :key="Account.id" :value="Account.id">
                    {{ Account.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="实付金额(元)" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-input-number v-model="purchase_account_item.payment_amount" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row gutter="16">
            <a-col :span="4">
              <a-form-model-item label="本单欠款(元)" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-input-number :value="amountOwed" :disabled="true" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定保存吗?" @confirm="create">
          <a-button type="primary" :loading="loading">保存</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <PurchaseInventorySelectModal v-model="materialsSelectModalVisible" @select="onSelectMaterial" />
    <GoodsUpdateModal v-model="updateModalVisible" :goods="targetMaterial" @update="updateMaterial" />
  </div>
</template>

<script>
import moment from "moment";
import { purchaseOrderDetail, purchaseOrderUpdate } from "@/api/purchasing";
import { userOption, accountsOption } from "@/api/option";
import NP from "number-precision";

export default {
  components: {
    PurchaseInventorySelectModal: () => import("@/components/PurchaseInventorySelectModal"),
    PurchaseFileUpload: () => import("@/components/PurchaseFileUpload"),
    SupplierInput: () => import("@/components/SupplierInput"),
    GoodsUpdateModal: () => import("@/components/GoodsUpdateModal"),
  },
  data() {
    return {
      description: "保存",
      warehouseItems: [],
      handlerItems: [],
      accountsItems: [],
      materialsSelectModalVisible: false,
      loading: true,
      model: {},
      form: {},
      rules: {
        number: [
          { required: true, message: "请输入编号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        supplier_name: [{ required: true, message: "请输入供应商", trigger: "change" }],
        handler: [{ required: true, message: "请选择制单人", trigger: "change" }],
        handle_time: [{ required: true, message: "请选择处理日期", trigger: "change" }],
        other_amount: [{ pattern: new RegExp(/^\d{0,14}(?:\.\d{0,2})?$/), message: "其他费用格式不正确", trigger: "change" }],
      },
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 80,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "名称",
          dataIndex: "goods_name",
          key: "name",
        },
        {
          title: "编号",
          dataIndex: "goods_number",
          key: "number",
        },
        {
          title: "规格",
          dataIndex: "goods_spec",
          key: "spec",
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit",
        },
        {
          title: "采购数量",
          dataIndex: "purchase_quantity",
          key: "purchase_quantity",
          scopedSlots: { customRender: "purchase_quantity" },
        },
        {
          title: "采购单价(元)",
          dataIndex: "purchase_price",
          key: "purchase_price",
          scopedSlots: { customRender: "purchase_price" },
        },
        {
          title: "金额",
          dataIndex: "totalAmount",
          key: "totalAmount",
          customRender: (value, item) => {
            if (item.isTotal) return value;
            value = NP.times(item.purchase_quantity, item.purchase_price);
            return item.goods ? NP.round(value, 2) : "";
          },
        },
        // {
        //   title: "交货日期",
        //   dataIndex: "delivery_date",
        //   key: "delivery_date",
        //   scopedSlots: { customRender: "delivery_date" },
        // },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      materialItems: [],
      columnsAccount: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "结算账户",
          dataIndex: "account",
          key: "account",
          width: 200,
          scopedSlots: { customRender: "account" },
        },
        {
          title: "付款金额",
          dataIndex: "payment_amount",
          key: "payment_amount",
          width: 200,
          scopedSlots: { customRender: "payment_amount" },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
      purchase_account_items: [],
      purchase_account_item: {},

      targetMaterial: null,
      updateModalVisible: false,
    };
  },
  computed: {
    enableFinanceManage() {
      return this.$store.state.user.enableFinanceManage;
    },
    totalAmount() {
      let totalAmount = 0;
      for (let item of this.materialItems) {
        let amount = NP.times(item.purchase_quantity, item.purchase_price);
        totalAmount = NP.plus(totalAmount, amount);
      }

      totalAmount = NP.plus(totalAmount, this.form.other_amount || 0);
      return totalAmount;
    },
    amountOwed() {
      return NP.minus(this.totalAmount, this.purchase_account_item.payment_amount || 0);
    },
    goodsData() {
      // 统计合计
      let totalQuantity = 0,
        totalAmount = 0;
      for (let item of this.materialItems) {
        totalQuantity = NP.plus(totalQuantity, item.purchase_quantity);
        let amount = NP.times(item.purchase_quantity, item.purchase_price);
        totalAmount = NP.plus(totalAmount, amount);
      }
      return [
        ...this.materialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          purchase_quantity: totalQuantity,
          totalAmount: totalAmount,
        },
      ];
    },
    accountsData() {
      // 统计合计
      let totalAmount = 0;
      for (let item of this.purchase_account_items) {
        totalAmount = NP.plus(totalAmount, item.payment_amount);
      }
      return [
        ...this.purchase_account_items,
        {
          id: "-1",
          isTotal: true,
          payment_amount: totalAmount,
        },
      ];
    },
    userId() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    moment,
    initData() {
      userOption({ page_size: 999999, is_active: true }).then((data) => {
        this.handlerItems = data.results;
      });

      accountsOption({ page_size: 999999, is_active: true }).then((data) => {
        this.accountsItems = data.results;
      });

      this.loading = true;
      purchaseOrderDetail({ id: this.$route.query.id })
        .then((data) => {
          this.form = data;
          this.materialItems = data.purchase_goods_items;
          if (data.purchase_account_items.length > 0) {
            this.purchase_account_item = data.purchase_account_items[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handelAddAcount() {
      this.purchase_account_items.push({
        id: this.purchase_account_items.length + 1,
        account: "",
        payment_amount: 0,
      });

      this.purchase_account_items = [...this.purchase_account_items];
    },
    removeAccount(item) {
      this.purchase_account_items = this.$functions.removeItem(this.purchase_account_items, item);
    },
    changeAccount(value, item, idx) {
      let count = this.purchase_account_items.filter((_item) => {
        return _item.account == value;
      });
      if (count.length > 1) {
        this.$message.warn("已添加过改结算账户!");
        this.purchase_account_items[idx].account = "";
      }
    },
    changeWarehouse() {
      this.materialItems = [];
    },
    openMaterialModal() {
      // if (!this.form.warehouse) {
      //   this.$message.warn("请先选择仓库！");
      //   return false;
      // }
      this.materialsSelectModalVisible = true;
    },
    onSelectMaterial(item) {
      let index = this.materialItems.findIndex((_item) => _item.id == item.id);
      if (index != -1) {
        this.$message.warn("产品已存在");
        return;
      }
      this.materialItems = this.$functions.insertItem(this.materialItems, {
        id: -Date.now(),
        goods: item.id,
        goods_number: item.number,
        goods_name: item.name,
        goods_spec: item.spec,
        unit_name: item.unit_name,
        purchase_quantity: 1,
        purchase_price: item.purchase_price,
      });
    },
    removeMaterial(item) {
      this.materialItems = this.$functions.removeItem(this.materialItems, item);
    },
    updateMaterial(item) {
      const index = this.materialItems.findIndex((salesGoodsItem) => salesGoodsItem.goods === item.id);
      if (index !== -1) {
        const updateItem = {
          ...this.materialItems[index],
          goods_number: item.number,
          goods_name: item.name,
          goods_spec: item.spec,
          unit_name: item.unit_name,
        };
        this.materialItems.splice(index, 1, updateItem);
      }
    },
    editMaterial(item) {
      this.targetMaterial = item.goods;
      this.updateModalVisible = true;
    },
    create() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let ifHasEmptyGoods = false;
          let ifHasEmptyAccounts = false;

          // this.purchase_account_items.map((item) => {
          //   if (!item.account || item.payment_amount === "" || item.payment_amount === null) {
          //     ifHasEmptyAccounts = true;
          //   }
          // });
          // if (ifHasEmptyAccounts) {
          //   this.$message.warn("请将结算账户信息填写完整");
          //   return false;
          // }

          if (this.materialItems.length == 0) {
            this.$message.warn("未添加产品");
            return false;
          }
          this.materialItems.map((item) => {
            if (item.purchase_price === null || !item.purchase_quantity) {
              ifHasEmptyGoods = true;
            }
          });
          if (ifHasEmptyGoods) {
            this.$message.warn("采购单价和采购数量必填");
            return false;
          }

          const purchase_account_items = [];
          if (this.enableFinanceManage) {
            if (!this.purchase_account_item.account) {
              this.$message.warn("请选择结算账户");
              return;
            }

            if (this.purchase_account_item.account && this.purchase_account_item.payment_amount > 0) {
              purchase_account_items.push(this.purchase_account_item);
            }
          }

          this.loading = true;
          let formData = {
            ...this.form,
            purchase_account_items: [this.purchase_account_item],
            purchase_goods_items: this.materialItems.map((item) => {
              return {
                goods: item.goods,
                purchase_quantity: item.purchase_quantity,
                purchase_price: item.purchase_price,
                delivery_date: item.delivery_date,
              };
            }),
          };

          purchaseOrderUpdate(formData)
            .then((data) => {
              this.$message.success("保存成功");
              this.$router.push({ path: "/purchasing/purchase_record" });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    changeHandler(value) {
      const index = this.handlerItems.findIndex((item) => item.id === value);
      if (index !== -1) {
        this.form.order_person_phone = this.handlerItems[index]["phone"];
      }
    },
    selectSupplier(item) {
      console.log(item);
      this.form.contacts = item["contact"];
      this.form.supplier_address = item["address"];
      this.form.contact_phone = item["phone"];
      this.form.supplier_fax = item["fax"];
    },
    resetForm() {
      this.form = { other_amount: 0 };
      this.purchase_account_item = { payment_amount: 0 };

      this.materialItems = [];
      this.handelAddAcount();
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
